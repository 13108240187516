import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} || Vieira Passos PCMSO LTCAT PGR AVCB CLCB CETESB ABVTEX ESOCIAL PPP </title>
                    <meta name="description" content="Vieira Passos PCMSO LTCAT PGR AVCB CLCB CETESB ABVTEX ESOCIAL PPP" />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
