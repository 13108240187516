import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
// import GoogleMapReact, { GoogleMapsMarkerInterface } from "google-map-react";
import ContactTwo from "../elements/contact/ContactTwo";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends Component {
  static defaultProps = {
    center: {
      lat: -23.5068761,
      lng: -46.8747859,
    },
    zoom: 14,
  };

  render() {
    // const markers: GoogleMapsMarkerInterface[] = [];
    // markers.push({
    //   lat: -23.5068761,
    //   lng: -46.8747859,
    //   title: "Vieira Passos Eng",
    //   info: "Vieira Passos Eng do trabalho - www.vieirapassos.com.br",
    // });

    return (
      <React.Fragment>
        <PageHelmet pageTitle="Contato" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--27"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Entre em contato</h2>
                  <p>
                    Solicite uma proposta e conte com a melhor consultoria de
                    São Paulo
                  </p>
                  <p>
                    Certamente nossas condições e preços competitivos irão te
                    surpreender.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Contact Top Area  */}
        <div className="rn-contact-top-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiHeadphones />
                  </div>
                  <div className="inner">
                    <h4 className="title-clientes">Telefones</h4>
                    <br />
                    <p>
                      {/* <a href="tel:+055 11 4375 3766">+055 (Em Breve)</a> */}
                    </p>
                    <br />
                    <p>
                      <a href="https://api.whatsapp.com/send?phone=5511998401512&text=Contato%20via%20Site">
                        (11) 99840-1512
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title-clientes">Email</h4>
                    <br />
                    <br />
                    <p>
                      <a href="mailto:contato@vieirapassos.com.br">
                        contato@vieirapassos.com.br
                      </a>
                    </p>
                    <br />
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title-clientes">Endereço</h4>
                    <br />
                    <br />
                    <p>
                      <a href="https://goo.gl/maps/HtpepUB5GWjzmKiu6">
                        RUA CAMPOS SALES, 639 – SALA 1 <br />
                        CENTRO - BARUERI - SP.
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}
            </div>
          </div>
        </div>
        {/* End Contact Top Area  */}

        {/* Start Contact Page Area  */}
        <div className="rn-contact-page ptb--120 bg_color--1">
          <ContactTwo />
        </div>
        {/* End Contact Page Area  */}

        {/* Start Contact Map  */}
        {/* <div className="rn-contact-map-area position-relative">
          <div style={{ height: "650px", width: "100%" }}>
            <GoogleMapReact
              defaultCenter={this.props.center}
              defaultZoom={this.props.zoom}
              markers={markers}
              draggable={false}
              zoom={16}
              initialCenter={{ lat: -23.5068761, lng: -46.8747859 }}
            ></GoogleMapReact>
          </div>
        </div> */}
        {/* End Contact Map  */}

        {/* Start Brand Area */}
        <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default Contact;
