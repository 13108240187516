import React, { Component, Fragment } from "react";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import Slider from "react-slick";
import { slideSlick } from "../scripts/script";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Helmet from "../component/common/Helmet";

import {
  FiFileText,
  FiLayers,
  FiUsers,
  FiChevronUp,
  FiCheck,
} from "react-icons/fi";
import CounterOne from "../elements/counters/CounterOne";
import BrandTwo from "../elements/BrandTwo";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

const SlideList = [
  {
    textPosition: "text-right",
    bgImage: "bg_image--27",
    category: "",
    title: "Consultoria",
    description: "EM ENGENHARIA DE SEGURANÇA DO TRABALHO.",
    buttonText: "",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-right",
    bgImage: "bg_image--11",
    category: "",
    title: "Consultoria",
    description: "EM ENGENHARIA DE SEGURANÇA DO TRABALHO.",
    buttonText: "",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-right",
    bgImage: "bg_image--12",
    category: "",
    title: "Consultoria",
    description: "EM ENGENHARIA DE SEGURANÇA DO TRABALHO.",
    buttonText: "",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-right",
    bgImage: "bg_image--13",
    category: "",
    title: "Consultoria",
    description: "EM ENGENHARIA DE SEGURANÇA DO TRABALHO.",
    buttonText: "",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-right",
    bgImage: "bg_image--14",
    category: "",
    title: "Consultoria",
    description: "EM ENGENHARIA DE SEGURANÇA DO TRABALHO.",
    buttonText: "",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-right",
    bgImage: "bg_image--15",
    category: "",
    title: "Consultoria",
    description: "EM ENGENHARIA DE SEGURANÇA DO TRABALHO.",
    buttonText: "",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-right",
    bgImage: "bg_image--16",
    category: "",
    title: "Consultoria",
    description: "EM ENGENHARIA DE SEGURANÇA DO TRABALHO.",
    buttonText: "",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-right",
    bgImage: "bg_image--17",
    category: "",
    title: "Consultoria",
    description: "EM ENGENHARIA DE SEGURANÇA DO TRABALHO.",
    buttonText: "",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-right",
    bgImage: "bg_image--18",
    category: "",
    title: "Consultoria",
    description: "EM ENGENHARIA DE SEGURANÇA DO TRABALHO.",
    buttonText: "",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-right",
    bgImage: "bg_image--19",
    category: "",
    title: "Consultoria",
    description: "EM ENGENHARIA DE SEGURANÇA DO TRABALHO.",
    buttonText: "",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-right",
    bgImage: "bg_image--20",
    category: "",
    title: "Consultoria",
    description: "EM ENGENHARIA DE SEGURANÇA DO TRABALHO.",
    buttonText: "",
    buttonLink: "/contact",
  },
];

const ServiceListOne = [
  {
    icon: <FiFileText />,
    title: "AVCB",
    description:
      "Auxiliamos em todo o processo de obtenção do Auto de Vistoria do Corpo de Bombeiros.",
  },
  {
    icon: <FiLayers />,
    title: "Laudos Ocupacionais",
    description:
      "Prestamos consultoria para cuidar da saúde do seu funcionário.",
  },
  {
    icon: <FiUsers />,
    title: "Capacitação e treinamentos",
    description: "Realizamos treinamentos in loco das principais NRs.",
  },
];

const standardService = [
  {
    image: "01",
    title: "SEGURANÇA DO TRABALHO",
    description: "Atendemos os seguintes itens:...",
    items: [
      "PGR – Programa de Gerenciamento de Riscos - NR 9",
      "LAUDO ELÉTRICO - DIAGRAMA UNIFILAR - NR 10",
      "LAUDOS DE INSALUBRIDADE E PERICULOSIDADE - NR 15/16",
      "LAUDO ERGONÔMICO - NR 17",
      "PGRS - Programa de Gerenciamento de Resíduos Sólidos, conforme Lei 12.305",
      "PGRSS – Programa de Gerenciamento de Resíduos Sólidos da Saúde, conforme Lei 12.305",
      "PPP - PERFIL PROFISSIOGRÁFICO PROFISSIONAL, IN -INSS DC Nº 99/2003",
      "LAUDOS DE RUÍDO, NBR 10.151",
      "LTCAT - LAUDO TÉCNICO DAS CONDIÇÕES AMBIENTAIS DO TRABALHO, IN 095/2003 DO INSS",
      "PCA - PROGRAMA DE CONSERVAÇÃO AUDITIVA (Fundacentro)",
      "PPR - PROGRAMA DE PROTEÇÃO RESPIRATÓRIA (Fundacentro)",
      "Projetos Elétricos",
      "Elaboração de Diagramas Unifilares",
      "Prontuários de Instalações Elétricas(NR10)",
    ],
  },
  {
    image: "02",
    title: "LICENÇAS E REGULARIZAÇÕES",
    description: "Atendemos os seguintes itens:...",
    items: [
      "AVCB – AUTO DE VISTORIA DO CORPO DE BOMBEIROS",
      "CLCB – CERTIFICADO DE LICENÇA DO CORPO DE BOMBEIROS",
      "Instalação e vendas de sistemas de incêndio(hidrantes, extintores, mangueiras, bombas, sinalização de emergência)",
      "Elaboração de RIT - Relatório de Impacto do Trânsito",
      "Elaboração de RIV - Relatório de Impacto da Vizinhança",
      "Regularização de Imóveis",
      "Execução e Aprovação de Plantas",
      "Desdobros",
      "Unificação de Imóveis",
      "Usucapião",
      "Obtenção de Habite-se",
      "Projeto de Anistia de Imóveis junto à Prefeituras",
      "Projetos arquitetônicos",
      "Alvará de Funcionamento junto a prefeitura",
      "Obtenção de Licença da Vigilância Sanitária",
      "Desenvolvimento do LTA (Laudo Técnico de Análise)",
      "Obtenção da Licença da Cetesb, estadual e municipalizada, junto a secretaria do meio ambiente das prefeituras",
      "Obtenção do Ibama (inclui o Cadastro Técnico Federal)",
      "Obtenção da Licença do uso de produtos químicos controlados: Polícia Civil, Exército e Policia Federal",
    ],
  },
  {
    image: "03",
    title: "TREINAMENTOS OCUPACIONAIS",
    description:
      "Ministramos os seguintes cursos na modalidade Presenciais e EAD:...",
    items: [
      "NR 01 - ORDEM DE SERVIÇO",
      "NR 05 - SIPAT",
      "NR 06 - EQUIPAMENTOS DE PROTEÇÃO INDIVIDUAL",
      "NR 07 - PRIMEIROS SOCORROS",
      "NR 10 - SERVIÇOS EM ELETRICIDADE",
      "NR 11 - OPERADOR DE EMPILHADEIRA",
      "NR 12 - MÁQUINAS E EQUIPAMENTOS",
      "NR 13 - OPERADOR DE CALDEIRA",
      "NR 18 - INTEGRAÇÃO/PLATAFORMA ELEVATÓRIA",
      "NR 20 - LÍQUIDOS INFLAMÁVEIS",
      "NR 33 - ESPAÇO CONFINADO",
      "NR 35 - TRABALHO EM ALTURA",
      "NBR 14.276 - BRIGADA DE INCÊNDIO",
      "PLANO DE ABANDONO E EMERGÊNCIA, NBR-15219",
      "Treinamento de Operador de Ponte Rolante, conforme NR 11",
    ],
  },
  {
    image: "04",
    title: "MEDICINA DO TRABALHO",
    description: "Principais serviços e Exames Médicos Ocupacionais:...",
    items: [
      "Elaboração de Parecer Técnico",
      "Impugnação e Manifestação do Laudo Pericial",
      // "Exames Médicos Ocupacionais:",
      // "Clínico, Acuidade Visual,",
      // "Audiometria, Eletrocardiograma,",
      // "Eletroencefalograma, Espirometria,",
      // "Glicemia, Avaliação Psicossocial,",
      // "Raio X de Tórax, Toxicológico (para motoristas)",
    ],
  },
  {
    image: "05",
    title: "ASSISTÊNCIA TÉCNICA TRABALHISTA",
    description: "Atendemos os seguintes itens:...",
    items: [
      "Avaliação e estudo do processo trabalhista",
      "Formulação de Quesitos",
      "Acompanhamento da Perícia Trabalhista",
      "Acuidade Visual",
      "Audiometria",
      "Eletrocardiograma",
      "Eletroencefalograma",
      "Espirometria",
      "Raio-X de Tórax",
      "Hemograma",
      "Exame Clínico",
      "Avaliação Psicosocial",
    ],
  },
  {
    image: "06",
    title: "CONSULTORIA EM SEGURANÇA DO TRABALHO",
    description: "Prestamos consultoria em:...",
    items: [
      "ESOCIAL – Assessoria na implantação e operacionalização do programa",
      "CETESB",
      "ABVTEX",
      "2210 – Comunicação de Acidente de Trabalho",
      "2220 – Monitoramento da Saúde do Trabalhador",
      "2240 – Condições Ambientais do Trabalho, Agentes Nocivos",
    ],
  },
];

class CorporateBusiness extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    var namesItemOne = [
      "Esocial",
      "RIV - Relatório de Impacto da Vizinhança",
      "RIT - Relatório de Impacto de Trânsito",
      "LTA - Laudo Técnico de Análise",
    ];

    return (
      <Fragment>
        <Helmet pageTitle="Vieira Passos Engenharia do Trabalho " />

        {/* Start Header Area  */}
        <Header
          headerPosition="header--static logoresize"
          logo="logo"
          color="color-black"
        />
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div className="slider-activation">
            <Slider className="rn-slick-dot dot-light" {...slideSlick}>
              {SlideList.map((value, index) => (
                <div
                  className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                  key={index}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ""}
                          {value.title ? (
                            <h1 className="title">{value.title}</h1>
                          ) : (
                            ""
                          )}
                          {value.description ? (
                            <p className="description">{value.description}</p>
                          ) : (
                            ""
                          )}

                          {value.buttonText ? (
                            <div className="slide-btn">
                              <a
                                className="rn-button-style--2 btn-solid"
                                href={`${value.buttonLink}`}
                              >
                                {value.buttonText}
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start Service Area */}
        <div className="service-area ptb--30 bg_color--1">
          <div className="container">
            <div className="row service-one-wrapper">
              {ServiceListOne.map((val, i) => (
                <div
                  className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <a className="text-center" href="#">
                    <div className="service service__style--2">
                      <div className="icon">{val.icon}</div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p align="justify">{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Service Area */}

        {/* Start Featured Service Area  */}
        <div className="rn-featured-service-area pt--90 pb--120 bg_color--5">
          <div className="container">
            <div className="row">
              {/* Start Single Service  */}
              <div className="col-lg-3 col-md-6 col-12 mt--30">
                <div className="section-title">
                  <h2 className="title-clientes">Serviços</h2>
                  <p align="justify">
                    A Vieira Passos coloca seus clientes sempre em primeiro
                    lugar.
                  </p>

                  <p align="justify">
                    Oferecemos ao cliente o máximo em satisfação e qualidade,
                    prazo de entrega ágil, alta tecnologia a um ótimo custo
                    benefício.
                  </p>

                  <p align="justify">
                    Aqui você encontra a solução que procura para a saúde e bem
                    estar de sua empresa e colaboradores.
                  </p>
                  <div className="service-btn">
                    <a className="btn-transparent rn-btn-dark" href="/contact">
                      <span className="text">
                        Solicite um serviço personalizado
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              {/* End Single Service  */}

              {/* Start Single Service  */}
              <div className="col-lg-9">
                <div className="row">
                  {standardService.map((value, index) => (
                    <div className="col-lg-4 col-md-4 mt--30" key={index}>
                      <div className="standard-service">
                        <div className="thumbnail">
                          <img
                            src={`/assets/images/featured/corporate-${value.image}.webp`}
                            alt="Services Images"
                          />
                        </div>
                        <div className="content">
                          <h3>
                            <a href="/service-details">{value.title}</a>
                          </h3>
                          <Accordion
                            className="accordion-style--1"
                            preExpanded={"0"}
                            allowZeroExpanded
                          >
                            <AccordionItem>
                              <AccordionItemHeading>
                                <AccordionItemButton>
                                  <p>{value.description}</p>
                                </AccordionItemButton>
                              </AccordionItemHeading>
                              {value.items ? (
                                <AccordionItemPanel>
                                  <ul className="list-style--1">
                                    {value.items.map((name, index) => {
                                      return (
                                        <li key={index}>
                                          <FiCheck /> {name}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </AccordionItemPanel>
                              ) : (
                                ""
                              )}
                            </AccordionItem>
                          </Accordion>
                          {/* <a
                            className="btn-transparent rn-btn-dark"
                            href="/service-details"
                          >
                            <span className="text">Read More</span>
                          </a> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* End Single Service  */}
            </div>
          </div>
        </div>
        {/* End Featured Service Area  */}

        {/* Start Counterup Area */}
        <div
          className="counterup-area pb--80 pt--40 bg_image bg_image--17 theme-text-white"
          data-black-overlay="7"
        >
          <div className="container">
            <CounterOne />
          </div>
        </div>
        {/* End Counterup Area */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title-clientes">Sobre</h2>
                    <p align="justify" className="description">
                      Somos uma empresa especializada na prestação de serviços
                      de engenharia, medicina e segurança do trabalho, há mais
                      de 23 anos. Oferecemos uma assessoria completa para
                      atender as normas técnicas e exigências da fiscalização,
                      com qualidade, competência e eficácia, nos diversos
                      segmentos do mercado. Contamos com uma estrutura completa
                      e colaboradores das áreas Médicas, Técnicas e de
                      Engenharia oferecendo para sua empresa o serviço adequado
                      e o melhor custo benefício, independente do porte de sua
                      empresa.
                    </p>
                  </div>
                  <div className="mt--30">
                    <h4>Estrutura</h4>
                    <p align="justify">
                      Contamos com uma estrutura completa e colaboradores das
                      áreas Médicas, Técnicas e de Engenharia oferecendo para
                      sua empresa o serviço adequado e o melhor custo benefício,
                      independente do porte de sua empresa.
                    </p>
                    <p align="justify">
                      Prestamos consultoria também nos seguintes serviços:
                    </p>
                    <ul className="list-style--1">
                      {namesItemOne.map((name, index) => {
                        return (
                          <li key={index}>
                            <FiCheck /> {name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div className="thumbnail position-relative">
                  <img
                    className="w-100"
                    src="/assets/images/about/about-vieira.jpg"
                    alt="Treinamento Nr35"
                  />
                  <ModalVideo
                    channel="youtube"
                    isOpen={this.state.isOpen}
                    videoId="6xEVeWvtG9c"
                    onClose={() => this.setState({ isOpen: false })}
                  />
                  <button
                    className="video-popup position-top-center theme-color"
                    onClick={this.openModal}
                  >
                    <span className="play-icon"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}
        <div className="col-lg-6 col-sm-6 col-12">
          <h2 className="title-clientes">
            <span>Nossos Clientes</span>
          </h2>
        </div>
        {/* Start Brand Area  */}
        <div>
          <BrandTwo />
        </div>
        <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
          <div className="container">
            <div className="row"></div>
          </div>
        </div>
        {/* End Brand Area  */}

        {/* Start call To Action  */}
        <CallAction />
        {/* End call To Action  */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default CorporateBusiness;
