import React from "react";
const CallAction = () => {
  return (
    <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--120">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="inner text-center">
              {/* <span>READY TO DO THIS</span> */}
              <h2>Vamos trabalhar juntos</h2>
              <a className="rn-button-style--2" href="/contact">
                <span>Solicite um orçamento</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CallAction;
