import React, { Component } from "react";

class BrandTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <ul className="brand-style-2">
          <li>
            <img src="/assets/images/brand/4rambiental.png" alt="4rambiental" />
          </li>
          <li>
            <img
              src="/assets/images/brand/camarabarueri.png"
              alt="camarabarueri"
            />
          </li>
          <li>
            <img src="/assets/images/brand/carajas.png" alt="carajas" />
          </li>
          <li>
            <img src="/assets/images/brand/cea.png" alt="cea" />
          </li>
          <li>
            <img src="/assets/images/brand/gemalto.png" alt="gemalto" />
          </li>
          <li>
            <img src="/assets/images/brand/generalle.png" alt="generalle" />
          </li>
          <li>
            <img src="/assets/images/brand/graf.png" alt="graf" />
          </li>
          <li>
            <img src="/assets/images/brand/habibs.png" alt="habibs" />
          </li>
          <li>
            <img src="/assets/images/brand/marisa.png" alt="marisa" />
          </li>
          <li>
            <img src="/assets/images/brand/mchecon.png" alt="mchecon" />
          </li>
          <li>
            <img src="/assets/images/brand/nutriplant.png" alt="nutriplant" />
          </li>
          <li>
            <img src="/assets/images/brand/otis.png" alt="otis" />
          </li>
          <li>
            <img src="/assets/images/brand/plastilin.png" alt="plastilin" />
          </li>
          <li>
            <img src="/assets/images/brand/prefab.png" alt="prefab" />
          </li>
          <li>
            <img
              src="/assets/images/brand/prefeituraembudasartes.png"
              alt="prefeituraembudasartes"
            />
          </li>
          <li>
            <img src="/assets/images/brand/renner.png" alt="renner" />
          </li>
          <li>
            <img src="/assets/images/brand/schur.png" alt="schur" />
          </li>
          <li>
            <img src="/assets/images/brand/terram.png" alt="terram" />
          </li>
          <li>
            <img src="/assets/images/brand/thales.png" alt="thales" />
          </li>
          <li>
            <img src="/assets/images/brand/wapmetal.png" alt="wapmetal" />
          </li>
          <li>
            <img src="/assets/images/brand/atlascopco.png" alt="Atlas Copco" />
          </li>
          <li>
            <img src="/assets/images/brand/cartonale.png" alt="Atlas Copco" />
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
export default BrandTwo;
